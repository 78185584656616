<template>
  <div>
    <table class="table is-fullwidth is-striped">
      <table-header v-bind="{ columns, sort }" @change="setSort" />
      <tbody>
        <tr v-if="isEmpty">
          <td colspan="8">
            <table-empty text="No adverts available" />
          </td>
        </tr>
        <tr v-else v-for="(listing, index) in pagedListings" :key="index">
          <td>
            <span>{{ truncate(listing.make) }}</span>
          </td>
          <td>
            <div class="columns">
              <div class="column">
                <span>{{ truncate(listing.model) }}</span>
              </div>
              <div class="column is-narrow">
                <span
                  class="tag"
                  v-if="displayOutliers && isOutlier(listing.originalIndex)"
                  :class="{
                    'is-warning is-rounded':
                      displayOutliers && isOutlier(listing.originalIndex)
                  }"
                >
                  <span class="is-marginless">Outlier</span>
                </span>
              </div>
            </div>
          </td>
          <td>
            <span>{{ listing.price ? pounds(listing.price) : '-' }}</span>
          </td>
          <td>
            <span>
              {{ listing.mileage ? number(listing.mileage) : '-' }}
            </span>
          </td>
          <td>
            <span>{{ listing.year ? listing.year : '-' }}</span>
          </td>
          <td>
            <span>{{ formatDate(listing.timestamp, 'dd-MM-yyyy') }}</span>
          </td>
          <td>
            <div class="buttons">
              <button class="button has-text-soft" @click="openCard(listing)">
                <span class="icon">
                  <i class="fal fa-eye"></i>
                </span>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination v-bind="{ page, pages }" @change="setPage" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { pounds, number } from 'core/utils/format'
import { formatDate } from '@/utils/date.utils.ts'
import truncate from 'truncate'
import tableHeader from 'core/modules/table-header/mixins/tableHeader.mixin'
import pagination from 'core/modules/pagination/mixins/pagination.mixin'
import sort from '@/mixins/sort'
export default {
  name: 'ExplorerListings',
  mixins: [
    tableHeader({ fetch: 'fetch' }),
    sort,
    pagination({
      fn: {
        data: 'pages'
      }
    })
  ],
  components: {
    tableEmpty: () => import('core/components/TableEmpty'),
    tableHeader: () =>
      import('core/modules/table-header/components/TableHeader')
  },
  methods: {
    formatDate,
    openCard(listing) {
      this.$modal.open('explorer/ExplorerListing', listing)
    },
    truncate(value) {
      return truncate(value, 45)
    },
    pounds(value) {
      return pounds(value)
    },
    number(value) {
      return number(value)
    },
    isOutlier(index) {
      return !this.outlierFreeListings.some(
        listing => listing.originalIndex === index
      )
    }
  },
  computed: {
    ...mapState('explorer', ['displayOutliers']),
    ...mapGetters('explorer', ['outlierFreeListings', 'dataset']),
    perPage() {
      return 25
    },
    pages() {
      if (!this.dataset.length) return 0

      return Math.floor(this.dataset.length / this.perPage)
    },
    pagedListings() {
      const start = this.page === 1 ? 0 : this.page * this.perPage
      const end = start + this.perPage
      return this.dataset.slice(start, end)
    },
    columns() {
      return [
        { label: 'Make' },
        { label: 'Model' },
        { label: 'Price' },
        { label: 'Mileage' },
        { label: 'Year' },
        { label: 'Listing date' },
        { label: 'Actions' }
      ]
    },
    isEmpty() {
      return !this.dataset.length
    }
  }
}
</script>

export default {
  data: () => ({
    sort: {
      type: null,
      order: null
    }
  }),
  methods: {
    setSort(type) {
      if (this.sort.type !== type) {
        this.sort.type = type
        if (this.sort.order === null) {
          this.sort.order = 'asc'
        }
      } else {
        if (this.sort.order === 'desc') {
          this.sort.type = null
        }
        this.sort.order =
          this.sort.order === null
            ? 'asc'
            : this.sort.order === 'asc'
            ? 'desc'
            : null
      }

      if (this.page) {
        this.page = 1
      }
    },
    getSortIcon(type) {
      if (type === this.sort.type) {
        if (this.sort.order === 'asc') {
          return 'fas fa-sort-up'
        } else if (this.sort.order === 'desc') {
          return 'fas fa-sort-down'
        }
        return 'fas fa-sort'
      } else {
        return 'fal fa-sort'
      }
    }
  }
}
